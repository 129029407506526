import React from "react"
import { graphql } from "gatsby"
import get from "lodash.get"
import { withPreview } from "gatsby-source-prismic"

import SEO from "../components/seo"
import Grid from "../components/grid"
import Hero from "../components/video-hero"
import AboutHero from "../components/about-hero"
import ScrollIndicator from "../components/scroll-indicator"
import Bio from "../components/bio"
import PageWrapper from "../components/page-wrapper"

export const query = graphql`
  query About {
    site {
      siteMetadata {
        title
      }
    }

    allPrismicAbout {
      edges {
        node {
          prismicId
          data {
            headline {
              raw
            }
            text {
              raw
            }
            image {
              fluid(maxWidth: 800) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
  }
`

const AboutPage = (props) => {
  const page = get(props, `data.allPrismicAbout.edges[0].node.data`)
  if (!page) return null

  return (
    <PageWrapper name="about">
      <SEO title="About" />
      <Hero publicId="about_zre2yh">
        <AboutHero data={page} />
      </Hero>
      <Grid>
        <ScrollIndicator />
        <Bio data={page} />
      </Grid>
    </PageWrapper>
  )
}

export default withPreview(AboutPage)
