import styled from "styled-components"
import Grid from "./grid"
import DefaultText from "./text"

export const Container = styled(Grid)``
Container.defaultProps = {
  bg: "transparent",
  gridTemplateColumns: [
    "[full-start] 2.4rem [main-start h-start contact-info-start] repeat(4, 1fr) [contact-info-end h-end main-end] 2.4rem [full-end]",
    "[full-start] 1fr [main-start h-start contact-info-start] repeat(6, 1fr) [contact-info-end] repeat(2, 1fr) [h-end main-end] 1fr [full-end]",
    "[full-start] 1fr [main-start h-start contact-info-start] repeat(3, 1fr) [contact-info-end] repeat(8, 1fr) [h-end] 1fr [main-end] 1fr [full-end]",
  ],
  gridTemplateRows: "1fr auto",
  pb: ["y.mobile.1.0", "y.desktop.1.0"],
}

export const Text = styled(DefaultText.H3)``
Text.defaultProps = {
  as: "h2",
  variant: "dark",
  fontSize: ["mobile.0", "desktop.2"],
  lineHeight: ["mobile.2.0", "desktop.2.0"],
  mt: ["y.mobile.0.0", "y.desktop.0.0"],
}
