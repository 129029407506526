import React from "react"
import { useViewportScroll, useTransform } from "framer-motion"
import { Container, Label } from "./scroll-indicator.styles"
import Arrow from "./arrow"

export default function ScrollIndicator() {
  const { scrollYProgress } = useViewportScroll()
  const opacity = useTransform(scrollYProgress, [0, 0.1], [1, 0])

  const handleClick = () => {
    window.scrollTo({ top: window.innerHeight - 52, behavior: "smooth" })
  }

  return (
    <Container style={{ opacity }}>
      <Label onClick={handleClick}>See More</Label>
      <Arrow />
    </Container>
  )
}
