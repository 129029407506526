import React, { Fragment } from "react"
import Img from "gatsby-image"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import htmlSerializer from "../utils/htmlSerializer"
import { BioImage, BioText } from "./bio.styles"

const Bio = ({ data }) => (
  <Fragment>
    <BioImage>
      <Img fluid={data.image.fluid} alt={data.image.alt} />
    </BioImage>
    <BioText>
      <RichText
        render={data.text.raw}
        htmlSerializer={htmlSerializer()}
        linkResolver={linkResolver}
      />
    </BioText>
  </Fragment>
)

export default Bio
