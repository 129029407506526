import React from "react"
import { RichText } from "prismic-reactjs"
import { Container, Text } from "./contact-hero.styles"
import Grid from "./grid"

export default ({ data }) => {
  return (
    <Container>
      <Grid.Item gridColumn="h-start / h-end" alignSelf="center">
        <Text>{RichText.asText(data.headline.raw)}</Text>
      </Grid.Item>
    </Container>
  )
}
