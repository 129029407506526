import styled from "styled-components"
import { motion } from "framer-motion"

import Grid from "./grid"
import Text from "./text"

export const Container = styled(Grid.Item)`
  display: flex;
  align-items: center;
`
Container.defaultProps = {
  as: motion.div,
  gridColumn: "scroll-indicator-start / scroll-indicator-end",
}

export const Label = styled(Text.Span)`
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  cursor: pointer;
`

Label.defaultProps = {
  fontFamily: "mono",
  fontWeight: [4, 4],
  fontSize: ["mobile.-1", "desktop.-1"],
  lineHeight: ["mobile.2.0", "desktop.2.0"],
  color: "black.2",
}
