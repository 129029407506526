import styled from "styled-components"
import { space } from "styled-system"
import Grid from "./grid"

export const BioImage = styled(Grid.Item)`
  ${space}
`
BioImage.defaultProps = {
  gridColumn: "bio-image-start / bio-image-end",
  display: ["block", "block", "block"],
  mt: ["y.mobile.2.0", "y.desktop.2.0"],
}

export const BioText = styled(Grid.Item)`
  h3 {
    margin-bottom: 0;
  }
  h6 {
    margin-top: 0;
  }
`
BioText.defaultProps = {
  gridColumn: "bio-text-start / bio-text-end",
  mt: ["y.mobile.2.0", "y.desktop.2.0"],
}
